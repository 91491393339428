* {
  box-sizing: border-box;
}

.containerLoading {
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../image/backgroundImage.png');
  width: 499px !important;
  height: 100vh;
  margin: 0;
  margin: 0 auto;
}

.progress {
  display: flex;
  justify-content: center;
  height: 100vh !important;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  width: 499px;
}

.progressLogo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.progressImg {
  width: 352, 19px;
  height: 79, 81;
  margin-bottom: 30px;
}
.progressText {
  color: #3457a2;
  font-size: 13px;
}

@media only screen and (max-width: 500px) {
  .progress {
    zoom: 100%;
    display: flex;
    justify-content: center;
    height: 120vh !important;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    width: 100%;
    /* background-image: url('../../image/backgroundImage.png'); */
  }

  .containerLoading {
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-image: url('../../image/backgroundImage.png'); */
    width: 100% !important;
    height: 100vh;
    margin: 0;
    margin: 0 auto;
  }
}
@supports (-webkit-touch-callout: none) {
  @media only screen and (max-width: 500px) {
    .containerLoading {
      flex-direction: column;
      font-family: 'Montserrat', sans-serif;
      display: flex;
      align-items: center;
      justify-content: center;
      /* background-image: url('../../image/backgroundImage.png'); */
      width: 100% !important;
      height: 100vh;
      margin: 0;
      margin: 0 auto;
    }
  }
}
