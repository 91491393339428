body {
  margin: 0px !important;
  padding: 0px !important;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
h1 {
  font-size: 18px;
}
/* Just a parent container for the videos */
.videos {
  margin: auto;
  max-width: 499px;
}
/* Individual video container */
.video-wrap {
  background-color: black;
  position: relative;
  margin: auto;
  height: 100vh;
  /* max-width: 700px; */

  /* float:left; */
  /* overflow:auto; */
  /* display: block; */
}
/* video poster frame or video-player  */
.video-wrap .video-player {
  /* max-width: 700px; */
  padding: 0%;
  margin: 0%;
  width: 100%;
  height: 100%; /* For 100% screen height */

  z-index: 10;
}
/* SVG play button container */
.video-wrap .play-btn {
  position: absolute;
  width: 50px;
  height: 50px;
  right: 10%;
  top: 15%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 99999;
}

.video-wrap .play-btn svg {
  transition: all 0.2s ease;
  fill: green;
}

/* SVG next button container */
.next-btn-container {
  cursor: pointer;
  position: absolute;
  width: 45%;
  height: 50px;
  right: 10%;
  bottom: 10%;
  text-align: end;
  color: white;
  background-color: #000000bf;
  /* padding: 2%; */
  padding: auto;
  border: 1px solid black;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: translate(-50%, -50%); */
}

/* adjust your icon size, add different breakpoints as needed */
@media screen and (max-width: 600px) {
  .video-wrap {
    height: 100vh;
    /* float:left; */
  }

  .video-wrap .play-btn {
    width: 35px;
    height: 35px;
  }

  .video-wrap .video-player {
    width: 100%;
  }

  .next-btn-container {
    width: 50%;
    height: 35px;
    bottom: 15%;
  }
}
