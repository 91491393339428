.imageback {
  width: 128em;
  height: 100vh;
}
/* .menu {
  margin-top: 30%;
} */
.menu {
  z-index: 2;
}
.backgroundImg {
  background-image: url('../../image/backgroundImage.png');
  /* background-color: black; */
  display: flex;
  justify-content: center;
  /* height: 120vh !important; */
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-size: auto;
  /* width: 499px; */
}
/* .backgroundFotter {
  display: flex;
  position: absolute; 
  bottom: 0;
   height: 100%; 
  background-repeat: no-repeat;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  width: 499px;
}  */

.logo2 img:hover {
  border: 2px solid #3457a2;
  border-radius: 50%;
  cursor: pointer;
}
/* 
@supports (-webkit-touch-callout: none) {
  @media only screen and (max-width: 500px) {
    .zoom {
      zoom: 90% !important;
    }
  }

  @media only screen and (max-width: 500px) and (max-height: 926px) {
    .zoom {
      zoom: 70%;
    }
  }
} */

/* @media only screen and (max-height: 600px) {
  .zoom {
    zoom: 55% !important;
  }
}

@media only screen and (max-height: 900px) {
  .zoom {
    zoom: 70% !important;
  }
} */
