.menu .btn {
  width: 190px;
  height: 190px;
  margin-bottom: 40px;
  border-radius: 40px !important;
  text-align: left !important;
  color: #3457a2 !important;
  border: 6px solid #3457a2 !important;
  background-color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
  flex-direction: column;
}
.menu .btn:hover {
  width: 190px;
  height: 190px;
  margin-bottom: 40px;
  border-radius: 40px !important;
  text-align: left !important;
  color: #0fadd7 !important;
  border: 6px solid #0fadd7 !important;
  background-color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
  flex-direction: column;
}
.menu .btn:focus {
  width: 190px;
  height: 190px;
  margin-bottom: 40px;
  border-radius: 40px !important;
  text-align: left !important;
  color: #0fadd7 !important;
  border: 6px solid #0fadd7 !important;
  background-color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
  flex-direction: column;
}

.btnShare:hover {
  width: 80% !important;
  height: 71px !important;
  margin-bottom: 40px;
  border-radius: 50% !important;
  text-align: left !important;
  color: #0fadd7 !important;
  border: 6px solid #0fadd7 !important;
  background-color: white !important;
  justify-content: space-around !important;
  display: flex;
  align-items: center;
  margin: 15px auto;
  flex-direction: row !important;
}

.btnShare {
  width: 80% !important;
  height: 71px !important;
  margin-bottom: 40px;
  border-radius: 50% !important;
  text-align: left !important;
  color: #3457a2 !important;
  border: 6px solid #3457a2 !important;
  background-color: white !important;
  justify-content: space-around !important;
  display: flex;
  align-items: center;
  margin: 15px auto;
  flex-direction: row !important;
  padding: 40px 45px !important;
}

.menu {
  background-image: url('../../../image/backgroundImage.png');
  /* position: absolute;
  margin-top: 10%;
  /* margin-top: 40%; */
  /* width: 100%;
  animation: slideInFromRight 1.5s ease-in;  */
  /* display: flex; */
  position: absolute;
  /* flex: 1; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2%;
  /* margin-top: 40%; */
  width: 499px;
  height: 100%;
  animation: slideInFromRight 1.5s ease-in;
}
.logo {
  /* width: 40%; */
  margin-bottom: 20px !important;
  display: flex;
  margin: 0px auto;
}

/* .inputR {
  width: 379px !important;
  padding-left: 30px !important;
  height: 77px !important;
  border-radius: 39.5px !important;
  color: #8a8a8a !important;
  font-size: 18px !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
  margin-bottom: 18px !important;
} */
/* .btnR {
  width: 379px !important;
  height: 77px !important;
  border-radius: 39.5px !important;
  color: white !important;
  font-size: 18px !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
  margin-bottom: 18px !important;
} */
.form-group {
  margin: 0 auto;
  margin-left: 35px;
}

.form-group .logo {
  width: 55%;
  display: flex;
  margin: 50px auto;
}

.logo2 {
  display: flex !important;
  height: 70px !important;
  position: absolute !important;
  bottom: 120px !important;
  justify-content: center !important;
  overflow: hidden !important;
  line-height: 0 !important;
  margin: 30px 0 !important;
  font-size: 0.75rem !important;
  width: 100% !important;
}
.backgroundFotter {
  display: flex;
  position: absolute;
  bottom: 0;
  /* height: 100%; */
  background-repeat: no-repeat;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  width: 100% !important;
}
.menu .btn {
  line-height: 24px;
}

@media only screen and (max-width: 500px) {
  .menu {
    margin-top: 25% !important;
    width: 100%;
    zoom: 100% !important;
    background-image: none;
    display: block;
  }

  /* .modal {
    position: fixed;
    top: 1px !important;
    left: -26px !important;
    z-index: 1050;
    display: none;
    width: 409px !important;
    height: 100%;
    overflow: hidden;
    outline: 0;
  } */
  body {
    background-image: url('../../../image/backgroundImage.png');
  }
  .menu {
    margin-top: 5% !important;
    zoom: 70% !important;
  }
  .btnShare {
    width: 400px !important;
  }
  .btnShare:hover {
    width: 400px !important;
  }
  .menu .btn {
    line-height: 30px;
  }
  .logo2 {
    display: flex !important;
    position: absolute !important;
    bottom: 100px !important;
    justify-content: center !important;
    overflow: hidden !important;
    line-height: 0 !important;
    margin: 30px 0 !important;
    font-size: 0.75rem !important;
    width: 100% !important;
  }
  .logo2 img {
    height: 70px !important;
    width: 70px !important;
  }
  .backgroundFotter {
    display: flex;
    position: absolute;
    /* bottom: -100px; */
    /* height: 100%; */
    background-repeat: no-repeat;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    width: 102% !important;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100% !important;
    height: 100% !important;
    background-color: #000;
  }
  /* .modal-dialog {
    position: relative;
    width: 93% !important;
    margin: 0.5rem !important;
    margin-left: 27px !important;
    pointer-events: none;
  } */
}
@media only screen and (max-width: 320px) {
  .menu {
    margin-top: 5% !important;
    zoom: 61% !important;
  }
}
@supports (-webkit-touch-callout: none) {
  @media only screen and (max-width: 500px) {
    .menu .btn {
      line-height: 20px;
      font-size: 12px;
    }
    .btnShare {
      padding: 40px 45px !important;
      color: red;
      background-color: tomato !important;
    }

    .backgroundFotter {
      display: flex;
      position: absolute;
      bottom: -100px;
      /* height: 100%; */
      background-repeat: no-repeat;
      overflow: hidden;
      line-height: 0;
      font-size: 0.75rem;
      width: 100% !important;
    }
  }
}
